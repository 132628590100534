import * as React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { routes } from "./routes"




const theme = createTheme({
  palette: {
    primary: {
      main: "#D0021B"
    },
    secondary: {
      main: "#02d0b8"
    }
  },
});

const router = createBrowserRouter(routes)
function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <CssBaseline />
      </RouterProvider>
    </ThemeProvider>
  );
}

export default App;
