import * as React from "react";
import PropTypes from "prop-types";
import { Card, Paper, CardContent, CardHeader, CardMedia, Grid, Typography, CardActionArea, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import FeatureItem from "./FeatureItem";

// Data 
import { features } from "../data/features";

function FeatureItemNew({ feature }) {
  const theme = useTheme();
  return (
    // <Card sx={{ minWidth: 275 }} sm={6} elevation={6}>
    //   <CardHeader
    //     title={feature.imageLabel}
    //   >
    //   </CardHeader>
    //   <CardMedia
    //     component="img"
    //     width={160}
    //     image={feature.image}
    //     alt={feature.imageLabel}
    //   />
    //   <CardContent>
    //     <Typography variant="body2">
    //       {feature.description}
    //     </Typography>
    //   </CardContent>
    // </Card>
    // <Paper xs={12} sm={6}>


    <React.Fragment>
      <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
        <Card item elevation="6" borderRadius={4} sx={{ flex: 1, bgcolor: theme.palette.primary.main, color: "primary.contrastText" }}>
          <CardContent >
            <Typography component="h2" variant="h5" gutterBottom>
              {feature.title}
            </Typography>
            <CardMedia
              component="img"
              sx={{ width: 120, filter: "invert()", display: { xs: 'block', sm: 'block' }, padding: "8px", marginBottom: "10px", fill: "red" }}
              image={feature.image}
              alt={feature.imageLabel}
            />
            <Typography variant="subtitle2" color="primary.contrastText" paragraph>
              {feature.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>

  )
}

function FeatureList() {
  return (
    <React.Fragment>
      <Grid container spacing={4} justify="space-between" alignItems="stretch">
        {features.map((feature, idx) => (
          <FeatureItemNew key={`${feature.title}.${idx}`} feature={feature} />
        ))}
      </Grid>
    </React.Fragment>
  )
}

export default FeatureList;


