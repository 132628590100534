import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Box from "@mui/material/Box"
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as ToolbarLogo } from '../surety-financial-logo-294x105.svg';
// import { ReactComponent as ToolbarLogo } from '../../surety-financial-horizontal.svg';
import { ReactComponent as ToolbarLogo } from "../assets/horizontal-logo.svg";
import Typography from '@mui/material/Typography';
import MaterialLink from "@mui/material/Link";
import { Link, useRoutes } from "react-router-dom";

function Header(props) {
  const { sections, title = "" } = props;
  return (
    <React.Fragment>
      <Toolbar sx={{ display: "flex", flexDirection: "row", alignContent: "flex-start", borderBottom: 1, borderColor: 'divider' }}>
        <Link to="/">
          <ToolbarLogo style={{ flex: 1, maxHeight: "60px", padding: "8px" }} />
        </Link>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
          {!title ? null : title}
        </Typography>
        <IconButton>
        </IconButton>
        <Button component={MaterialLink} variant="outlined" size="small" sx={{ color: "primary.color", borderColor: "primary.color" }} href="https://suretyfinancial.factorview.com/login">
          Sign In
        </Button>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;