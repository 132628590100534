import * as React from "react";

export const features = [
  {
    title: 'Same Day Funding',
    // date: '\n',
    description:
      'We offer quick, same-day funding to support our clients in the growth of their businesses',
    image: './funding.svg',
    imageLabel: 'Image Text',
  },
  {
    title: 'Billing and Collections',
    // date: '\n',
    description:
      ' Our comprehensive services include billing, collection management, and credit check services at no additional cost',
    image: "./invoice.svg",
    imageLabel: 'Image Text',
  },
  {
    title: 'Transparency',
    description:
      'Straightforward, flat rates with no hidden fees. 24/7 access to an online portal for up-to-date account information',
    image: './transparency.svg',
    imageLabel: 'Image Text'
  },
  {
    title: 'Top Tier Customer Service',
    description:
      ' Assigned Account Representatives offering personalized customer service',
    image: './headset.svg',
    imageLabel: 'Image Text'
  }
];

