import * as React from "react";
import PropTypes from "prop-types";

import { Box, Grid, Typography, Link, Paper, Container, useTheme } from "@mui/material";



function ContactUs({ title, phone, email, address }) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

        // backgroundImage: `url(${post.image})`,
      }}
    >
      {/* <Grid container > */}



      <Box
        sx={{
          position: 'relative',
          p: { xs: 3, md: 6 },
          pr: { md: 0 },
        }}
      >
        <Typography component="h1" variant="h3" color="inherit" gutterBottom >
          {title}
        </Typography>
        <Typography variant="h5" color="inherit" paragraph>
          Call: {phone}
        </Typography>
        <Typography variant="h5" color="inherit" paragraph>
          {email}
        </Typography>
        <Typography variant="h7" color="inherit" paragraph>
          {address}
        </Typography>
        {/* <Link variant="subtitle1" href="#">
              {linkText}
            </Link> */}
      </Box>
      {/* </Grid> */}
    </Paper>
  );
}

export default ContactUs;

ContactUs.propTypes = {
  title: PropTypes.string,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
}