import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";

import { sections } from "../data/sections";
import { Outlet } from "react-router-dom";

function SinglePageLayout() {

  return (
    <React.Fragment>

      <Header title="" sections={sections} />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </React.Fragment>
  )
}

export default SinglePageLayout;