import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as MaterialLink } from '@mui/material';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material';



function Copyright(props) {
  const { theme } = props;
  return (
    <Typography variant="body2" color={theme.palette.text.secondary} align="center">
      {'Copyright © '}
      <MaterialLink color="inherit" href="https://www.surety-financial.com">
        https://www.surety-financial.com
      </MaterialLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function PrivacyPolicy(props) {
  const { theme } = props;
  return (
    <Typography variant="body2" color={theme.palette.text.secondary} align="center">
      <MaterialLink component={Link} color="inherit" to="/privacy-policy">
        Privacy Policy
      </MaterialLink>
    </Typography>
  )
}

function Footer(props) {
  const { description, title } = props;
  const theme = useTheme();
  return (
    <Box component="footer" sx={{ bgcolor: theme.palette.background.paper, py: 6 }}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {description}
        </Typography>
        <Copyright theme={theme} />
        <PrivacyPolicy theme={theme} />
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;