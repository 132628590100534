import * as React from "react";

import { Home } from "./pages/Home"
import Error from "./pages/Error";
import NoMatch from "./pages/NoMatch";

import Layout from "./layouts/SinglePageLayout"
import PrivacyPolicy from "./pages/PrivacyPolicy";

export const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "*", element: <NoMatch /> }
    ],
    errorElement: <Error />

    // element: <HomePage />,
    // element: <InDevelopment />,
  }
]

