import { Container } from "@mui/system";
import * as React from "react";
import { useRouteError, Link } from "react-router-dom";

import { Grid } from "@mui/material";

function NoMatch() {

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid maxWidth="lg" spacing={5} sx={{ mt: 3 }}>

          <h1>Oops!</h1>
          <h2>It looks like you are lost.</h2>
          <Link to="/">Go back to home page</Link>

        </Grid>
      </Container>
    </React.Fragment>
  );

}
export default NoMatch;