import { Container } from "@mui/system";
import * as React from "react";
import { useRouteError } from "react-router-dom";

import { Grid } from "@mui/material";


function ErrorPage() {
  const error = useRouteError();
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid maxWidth="lg" spacing={5} sx={{ mt: 3 }}>

          <h1>Oops!</h1>
          <h2>Sorry an unexpected error has occurred.</h2>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>

        </Grid>
      </Container>
    </React.Fragment>
  );

}
export default ErrorPage;