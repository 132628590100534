import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import styled from "@emotion/styled"


const Img = styled.img`
  display: block;
  position: absolute;
  margin: auto;
  width: 100vw;
`;
const Wrapper = styled.div`
  position: relative;
  isolation: isolate;
`;

const Image = (srcSet, src, alt, backgroundColor, opacity, children) => (
  <Wrapper
    style={{
      "--backgroundColor": backgroundColor,
      "--opacity": opacity,
    }}
  >
    <Img srcSet={srcSet} src={src} alt={alt} />
    {children}
  </Wrapper>
);


function FeatureItem(props) {

  const { feature, reversed } = props;

  return (
    reversed ?
      <Grid container item xs={12} sm={6} >
        {/* <CardActionArea component="a" href="#"> */}
        <Card sx={{
          display: 'flex',
          height: "100%",

        }} elevation="8">
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={feature.image}
            alt={feature.imageLabel}
          />
          <CardContent sx={{ flex: 1, bgolor: "primary.main" }}>
            <Typography component="h2" variant="h5" gutterBottom>
              {feature.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {feature.date}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {feature.description}
            </Typography>
          </CardContent>
        </Card>
        {/* </CardActionArea> */}
      </Grid>
      :
      <Grid container item xs={12} sm={6}>
        {/* <CardActionArea component="a" href="#"> */}
        <Card sx={{ display: 'flex', height: "100%" }} elevation="8">

          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5" gutterBottom>
              {feature.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {feature.date}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {feature.description}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={feature.image}
            alt={feature.imageLabel}
          />
        </Card>
        {/* </CardActionArea> */}
      </Grid>
  );
}

FeatureItem.propTypes = {
  feature: PropTypes.shape({
    // date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  reversed: PropTypes.bool
};

FeatureItem.defaultProps = {
  reversed: false
}

export default FeatureItem;