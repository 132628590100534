import * as React from "react";

import Banner from "./components/Banner";


import { Grid, List, Container } from "@mui/material";


import { sections } from "../../data/sections";
import post1 from './data/blog-post.1.md';
import post2 from './data/blog-post.2.md';
import post3 from './data/blog-post.3.md';
import { sidebar } from "../../data/sidebar";
import ContactUs from "./components/ContactUs"
import MissionStatement from "./components/MissionStatement";
import FeatureList from "./components/FeatureList";


// import bannerImage from "./assets/blackonwhite-truck-banner@2584x768.png"
import bannerImage from "./assets/blackandwhitetruckbanner.jpg"

// import { ReactComponent as BannerImage } from "../blackonwhite-truck-banner@2584x768.png"
const contactUsData = {
  title: "Grow your business today!",
  phone: "+1.435.485.7347",
  email: "support@surety-financial.com",
  address: `1611 East 2450 South Suite 5B \n St. George, UT 84790`
};

const posts = [post1, post2, post3];

const mainFeaturedPost = {
  title: "",
  description: "",
  // image: 'https://source.unsplash.com/random',
  image: bannerImage,
  imageText: 'main image description',
  linkText: '',
};





function Home() {

  return (
    <React.Fragment>
      <Banner post={mainFeaturedPost} />
      {/* <Grid container maxWidth="lg" align="center" spacing={5} sx={{ mt: 3 }}> */}
      <Container maxWidth="lg">
        <Grid container align="center" spacing={5} sx={{ mt: 3 }}>
          <Grid item>
            <MissionStatement />
          </Grid>
          <Grid item>
            <FeatureList />
          </Grid>
          <Grid item xs={12} >
            <ContactUs
              title={contactUsData.title}
              phone={contactUsData.phone}
              email={contactUsData.email}
              address={contactUsData.address}
            />
          </Grid>

        </Grid>


      </Container>

    </React.Fragment>
  );
}
export default Home;