import * as React from "react";
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
function MissionStatement() {

  return (
    <React.Fragment>
      <Container>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Our Mission

        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          {/* Surety Financials&apos; mission is to provide ongoing returns for the
          aggregated portfolios of accounts receivable held by the business and
          offer our clients outstanding service. */}
          We support the growth of our clients by providing comprehensive factoring services -
          including same-day funding, billing, and collection management -
          at a reasonable, transparent price.
        </Typography>
      </Container>

    </React.Fragment>
  );
}

export default MissionStatement;